import React from 'react'
import {navigateTo} from 'gatsby-link'
import Gallery from 'react-photo-gallery'

import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import Card from '../components/card'
import Button from '../components/button'

import Background from '../images/aitken/2dark.jpg'

import img8 from '../images/aitken/8.jpg'
import img7 from '../images/aitken/7.jpg'
import img1 from '../images/aitken/1.jpg'
import img6 from '../images/aitken/6.jpg'
import img3 from '../images/aitken/3.jpg'

const photos = [
  {
    src: img8,
    width: 960,
    height: 640
  },
  {
    src: img7,
    width: 960,
    height: 640
  },
  {
    src: img1,
    width: 960,
    height: 640
  },
  {
    src: img6,
    width: 960,
    height: 640
  },
  {
    src: img3,
    width: 960,
    height: 640
  }
]

const Aitken = ({location}) => {
  return(
    <Layout path={location.pathname}>
      <JumboBackground
        background={Background}
      />
      <CardBox title="Aitken College" noSpacer center>
        <Card>
          <Gallery photos={photos}/>
          <br/>
          <Button dark onClick={() => navigateTo('/projects')}>Back to projects</Button>
        </Card>
      </CardBox>
    </Layout>
  )
}

export default Aitken
